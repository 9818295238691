import React, { Suspense, lazy, useEffect } from 'react';
// material
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { WalletIcon, ElicashIcon } from '@assets';
import { contact } from '@utils/customText';
import dayjs from 'dayjs';
import { changeToLowerCase } from '@utils/common';
import { useSelector } from 'react-redux';
// component start
import { payNow } from '@reducers/bookingReducer';
import { useDispatch } from 'react-redux';
import { ConfirmAction } from '@components/Common/Cta/ConfirmAction';
import useAnalytics from '@components/Common/Analytics';
import '../bookingConfirm.scss';
import Elivashcard from '@components/Common/wallet/Elivashcard';
const PropertyInfo = lazy(() => import('@components/BookInfo/PropertyInfo'));
const InfoField = lazy(() => import('@components/Common/InfoField'));

// component startPropertyInfo
const BookingConfirmed = (props) => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);
  const { confirmationDetails } = props;

  const preventDefault = (event) => event.preventDefault();
  const handleRemaningPayment = () => {
    const data = {
      bookingId: confirmationDetails?.id,
      property_payment_term_id: null,
      guest_user_id: confirmationDetails?.GuestUser?.id,
    };
    dispatch(payNow(data));
  };

  useEffect(() => {
    // Track page view on component mount
    trackEvent('Successful Payment', {
      booking_id: confirmationDetails?.Booking?.ota_booking_code,
      firstName: confirmationDetails?.GuestUser?.User?.first_name,
      lastName: confirmationDetails?.GuestUser?.User?.last_name,
      mobile: confirmationDetails?.GuestUser?.User?.mobile,
      email: confirmationDetails?.GuestUser?.User?.email,
      room_nights_count: confirmationDetails?.room_nights_count,
      checkin: dayjs(confirmationDetails?.checkin_on).format('DD MMM, YYYY'),
      checkout: dayjs(confirmationDetails?.checkout_on).format('DD MMM, YYYY'),
      adults: confirmationDetails?.adults,
      children: confirmationDetails?.children,
      guests_count: confirmationDetails?.guests_count,
      room_nights_count: confirmationDetails?.room_nights_count,
      paid_amount:
        confirmationDetails?.ProformaInvoice?.OrderPaymentTerm?.amount,
      outstanding_amount:
        confirmationDetails?.Property?.OutstandingPayment?.payable_amount,
      city: confirmationDetails?.Property?.Address?.city,
      area: confirmationDetails?.Property?.Address?.area,
      propertyId: confirmationDetails?.property_id,
      propertyName: confirmationDetails?.Property?.name,
      user_id: confirmationDetails?.GuestUser?.id,
      total_price: confirmationDetails?.Order?.payable_amount,
      payment_method: '',
      utmCampaign: utmCampaign,
      utmSource: utmSource,
      paymentType:
        confirmationDetails?.Order?.lifecycle_stage_id === 5
          ? 'Partial'
          : 'Full',
    });
    // Other useEffect code...
  }, [confirmationDetails?.Booking?.ota_booking_code]);

  return (
    <>
      <Container className='aboutContainer' disableGutters maxWidth='xl'>
        <Container className='bookingConfirmContainer' maxWidth='lg'>
          <div className='EL-bookingContainerHeader'>
            <Typography variant='subtitle2' gutterBottom>
              <span className='El-success'>Booking Confirmed</span>
            </Typography>
            <Typography variant='h4' gutterBottom>
              Your booking is confirmed!
            </Typography>

            <Typography variant='subtitle1' gutterBottom>
              {`We’ve sent the confirmation to ${
                confirmationDetails?.GuestUser?.User?.mobile
              } and
              ${changeToLowerCase(
                confirmationDetails?.GuestUser?.User?.email
              )}`}
            </Typography>
          </div>
          <Grid className='EL-conformContaner' container spacing={10}>
            <Grid item md={8} xs={12}>
              <Card className='EL-bookingConfirmPaper' elevation={3}>
                <CardContent>
                  <Grid container spacing={5}>
                    {/* selected property detail   */}
                    <Grid className='EL-PDInfo' item xs={12}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <PropertyInfo
                          fetchingBookingDetailsRes={confirmationDetails}
                          inventorySoldOut={false}
                        />
                      </Suspense>
                    </Grid>

                    {/* booking details */}
                    <Grid className='EL-PDInfoBottom' item xs={12}>
                      <Typography variant='h6' gutterBottom>
                        {`Booked for ${confirmationDetails?.room_nights_count} Nights`}
                      </Typography>

                      <Suspense fallback={<div>Loading...</div>}>
                        <Grid container spacing={2}>
                          <>
                            <InfoField
                              xs={6}
                              label='Check-In'
                              info={dayjs(
                                confirmationDetails?.checkin_on
                              ).format('DD MMM, YYYY')}
                              checkin_on
                            />
                            <InfoField
                              xs={6}
                              label='Check-Out'
                              info={dayjs(
                                confirmationDetails?.checkout_on
                              ).format('DD MMM, YYYY')}
                              checkout_on
                            />
                            <InfoField
                              xs={6}
                              label='No. of Guests'
                              info={`${confirmationDetails?.guests_count} Guests`}
                            />
                            <InfoField
                              xs={6}
                              label='Booking ID'
                              info={`${confirmationDetails?.Booking?.ota_booking_code}`}
                            />
                          </>

                          <InfoField
                            xs={12}
                            info={`₹ ${
                              confirmationDetails?.Order?.lifecycle_stage_id ===
                              5
                                ? confirmationDetails?.ProformaInvoice
                                    ?.OrderPaymentTerm?.amount
                                : confirmationDetails?.Order?.payable_amount
                            }`}
                            cardInfo='Paid'
                            //cardInfo='Paid via Credit Card (XXX-9837)'
                          />
                        </Grid>
                      </Suspense>
                      {/* <> hello </> */}
                      <div className='EL-ConfirmBookingPage'>
                        <Elivashcard
                          giftImage
                          elicash={
                            confirmationDetails?.cashback_on_booking
                              ? confirmationDetails?.cashback_on_booking
                              : 0
                          }
                        />
                      </div>
                      {confirmationDetails?.Order?.lifecycle_stage_id === 5 && (
                        <Chip
                          className='El-RemaningChip'
                          label={
                            `Please pay the remaining balance of ₹ ${(confirmationDetails?.Property?.OutstandingPayment?.payable_amount).toFixed(
                              2
                            )} within 7 days for a hasslefree Check-in`
                            //'Remaining booking amount of ₹ 60,000  has to be paid by DD/MM/YYYY to avoid cancellation charges'
                          }
                          onDelete={handleRemaningPayment}
                          deleteIcon={
                            <Button
                              variant='contained'
                              color='success'
                              disableElevation
                              size='large'
                            >
                              Pay remaining amount
                            </Button>
                          }
                          // variant='outlined'
                        />
                      )}
                    </Grid>

                    {/* booking details end */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* booking right  action button */}
            <Grid className='El-confirmStickyRight' item md={4} xs={12}>
              <ConfirmAction
                variant='contained'
                avtar={<PinDropOutlinedIcon fontSize='small' />}
                title='Get Directions'
                color='secondary'
                action={`${confirmationDetails?.Property?.Address?.google_maps_business_link}`}
                actionType='link'
              />

              {/* <ConfirmAction
                variant='outlined'
                avtar={<FileDownloadOutlinedIcon fontSize='small' />}
                title='Download Details'
                color='secondary'
              /> */}
              <ConfirmAction
                variant='outlined'
                avtar={<CallOutlinedIcon fontSize='small' />}
                title='Contact Us'
                color='info'
                action={`https://wa.me/${contact.whatsApp}?text=${contact.whatsAppText}`}
              />
              <ConfirmAction
                variant='outlined'
                title=' Go to Home'
                color='info'
                action={'/'}
                actionType='link'
              />
              <div className='El-confirmBanner'>
                <img
                  src='https://d2lhlmuanwy6qz.cloudfront.net/image_333_7398ddb392.jpg?w=164&h=164&fit=crop&auto=format'
                  srcSet='https://d2lhlmuanwy6qz.cloudfront.net/image_333_7398ddb392.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`'
                  alt={''}
                  loading='lazy'
                />
              </div>
              <Box onClick={preventDefault} className='El-buttonBox'>
                <Link href='#' underline='always'>
                  <CircleIcon /> FAQs
                </Link>
                <Link href='#' underline='always'>
                  <CircleIcon /> +91-9988776655
                </Link>
                <Link href='#' underline='always'>
                  <CircleIcon /> Support
                </Link>
              </Box>
            </Grid>
            {/* booking right  action end */}
          </Grid>
        </Container>
      </Container>
    </>
  );
};
export default BookingConfirmed;
